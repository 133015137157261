import router from "../router/index";
import { getToken, setToken, setOtherTokens } from "./auth"; // get token from cookie
import getPageTitle from "./get-page-title";
import store from "../store/index";
import { Notification } from "element-ui";

router.beforeEach(async (to, from, next) => {
  if (window.location.search) {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("token")) {
      let token = urlParams.get("token");
      setToken(token);
      store.commit("auth/SET_TOKEN", token);

      let gomax_token = "";
      if (urlParams.has("gomax_token")) {
        gomax_token = urlParams.get("gomax_token");
      }
      let gocrm_token = "";
      if (urlParams.has("gocrm_token")) {
        gocrm_token = urlParams.get("gocrm_token");
      }
      let other_tokens = {
        gomax_token: gomax_token,
        gocrm_token: gocrm_token
      };
      setOtherTokens(other_tokens);
    }
  }
  // set page title
  document.title = getPageTitle(to.meta.title);
  // determine whether the user has logged in
  const hasToken =
    store.getters.token != null ? store.getters.token : getToken();
  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRole = store.getters.role;
      if (hasRole && hasRole != undefined) {
        next();
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          await store
            .dispatch("auth/getAuth")
            .then(res => {})
            .catch(async err => {
              console.log("permission no role try-catch");
              next(`/login?redirect=${to.path}`);
              await store.dispatch("auth/resetToken");
            });
          if (window.location.search) {
            let urlParams_2 = new URLSearchParams(window.location.search);
            if (urlParams_2.has("token")) {
              try {
                window.location.href = process.env.VUE_APP_SELF_URL;
              } catch (err) {
                await store.dispatch("auth/resetToken");
                Notification.error(error || "Has Error");
                next(`/login?redirect=%2F`);
              }
            } else {
              next();
            }
          } else {
            next();
          }
          next();
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch("auth/resetToken");
          Notification.error(error || "Has Error");
          next(`/login?redirect=${to.path}`);
        }
      }
    }
  } else {
    /* has no token*/
    if (to.path == "/login") {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      if (
        to.name == "applicationCandidates" ||
        to.name == "openStaffProfile" ||
        to.name == "openVacancy"
      ) {
        return next();
      }
      next(`/login?redirect=${to.path}`);
    }
  }
});

router.afterEach(() => {
  // finish progress bar
});
