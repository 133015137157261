<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header my-top-khan-title"
                >
                    <p class="large--title m-0" :class="mode ? '' : 'content__titlenight'">{{$t("message.employees")}} <span v-loading="loadStaff">({{staffCount?staffCount:0}})</span></p>
                    <span  v-can="'staffs.excel'">
                      <export-excel
                        v-if="excel_list.length"
                        class="btn excel_btn"
                        :data="excel_list"
                        worksheet="My Worksheet"
                        name="users.xls"
                        :fields="excel_fields" >
                        <el-button size="mini">
                          <i class="el-icon-document-delete"></i>  {{$t("message.excel")}}
                        </el-button>
                      </export-excel>
                    </span>
                    <el-button @click="closeModal()" type="warning" plain> {{$t("message.close")}}</el-button>
                </div>
            </div>
            <!-- app-modal__header end -->
            <div class="app-modal__body p-5 " v-loading="loadStaff">
               <div class="elemet-content">
                  <el-row :gutter="20">
                    <el-col :span="24" v-for="(staff, index) in staff_list"
                            :key="'staff-' + index"
                           >
                        <div class="timeline-items__right rounded-sm w-100  p-3" @click="pushToProfile(staff)" :class="mode ? '' : 'performance__itemnight'">
                            <div class="info-user-left2">
                                <div class="img">
                                    <img
                                      :src="
                                        staff.image
                                          ? baseUrl + staff.image.path
                                          : 'img/avatar.jpeg'
                                      "
                                      alt=""
                                    />
                                  </div>
                                <div class="name-info-user-left">
                                    <div class="name_u">
                                      {{ staff.name + " " + staff.last_name }}
                                    </div>
                                    <div class="position_u" v-if="staff.position">
                                      {{ staff.position.name }}
                                    </div>
                                     <div class="position_u" >
                                      {{ staff.id }}
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
               </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";
export default {
    name: "show-users",
    mixins: [drawer],
    components: {},
    components: {},
    data() {
        return {
           excel_fields: {
              '№': "id",
              Имя: "name",
              Фамилия: "last_name",
              Отчество: "middle_name",
              Должность: "position",
              Компания: "company",
            },
          loadStaff: false,
            baseUrl: process.env.VUE_APP_URL.substring(
              0,
              process.env.VUE_APP_URL.length - 3
            ),
        };
    },
    computed: {
    ...mapGetters({
        staff_list: "homeStaff/staff_list",
        excel_list: "homeStaff/excel_list",
        staffCount: "homeStaff/staffCount",
        mode:"MODE"
    }),
  },
  methods: {
    ...mapActions({
        getStaffCheckInList: "homeStaff/staff_list_check_in",
        getStaffNotCheckInList: "homeStaff/staff_list_not_check_in",
        getStaffNotConnectTelegramList: "homeStaff/not_connect_telegram",
        getStaffWaitingAnswerList: "homeStaff/waiting_answer_telegram",
        getHaveSalaryStaffs: "homeStaff/getHaveSalaryStaffs",
        getDoesntHaveSalayStaffs: "homeStaff/getDoesntHaveSalayStaffs",
        getMoodStaffs: "homeStaff/getMoodStaffs",
    }),

    opened(child) {
      if (!this.loadStaff && child.method=="check_in") {
        this.loadStaff = true;
        this.getStaffCheckInList(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
      if (!this.loadStaff && child.method=="getMoodStaffs") {
        this.loadStaff = true;
        this.getMoodStaffs(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
      else if(!this.loadStaff && (child.method=="no_check_in")){
        this.loadStaff = true;
        this.getStaffNotCheckInList(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });     
      }
      else if(!this.loadStaff && (child.method=="not_connect_telegram")){
        this.loadStaff = true;
        this.getStaffNotConnectTelegramList(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });     
      }
      else if(!this.loadStaff && (child.method=="waiting_answer_telegram")){
        this.loadStaff = true;
        this.getStaffWaitingAnswerList(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });     
      }
      else if(!this.loadStaff && (child.method=="haveSalaryStaffs")){
        this.loadStaff = true;
        this.getHaveSalaryStaffs(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });     
      }
      else if(!this.loadStaff && (child.method=="doesntHaveSalayStaffs")){
        this.loadStaff = true;
        this.getDoesntHaveSalayStaffs(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });     
      }

    },
    closeModal() {
      this.$emit("closeUserModal", false);
    },
     pushToProfile(staff) {
      // this.$router.push({
      //   name: "profile",
      //   params: { id: staff.id },
      // });
      let route = this.$router.resolve({
        name: "profile",
        params: {
          id: staff.id,
        },
      });

      window.open(route.href, "_blank");
    },
  }
};
</script>
<style lang="scss" >
.timeline-items__right {
    margin-bottom: 20px;

    .info-user-left2 {
        display: flex;
        align-items: start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .img {
            margin-right: 10px;
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
        }
    }
    .name_u {
        font-weight: bold;
        font-size: 15px;
    }
    .position_u {
        color: #8a8a8a;
        font-size: 12px;
        font-weight: 300;
        margin-top: 2px;
    }
    .salary {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
    }
}
.info-user-left2{
    display: flex;
    align-items: center;
    background-clip: border-box;
    border-radius: 0.428rem;
    border: 1px solid rgba(34,41,47,.1254901961);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
    // box-shadow: 0 4px 24px 0 rgb(34 41 47 / 1%);
    width: 100%;
    cursor: pointer;
    padding: 10px;
}
.performance__itemnight{
  background: #0e0e1ed4;
    .info-user-left2{
      border: 1px solid rgba(78, 78, 80, 0.622);
      box-shadow: 0 4px 24px 0 rgba(22, 22, 60, 0.677);
      .position_u{
        color: #fff !important;
      }
    }
    
}
.my-top-khan-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
     flex-wrap: nowrap;
}
.info-user-left2{
  flex-wrap: nowrap !important;
}
.elemet-content{
  padding: 0 15px;
  height: 100%;
  overflow: auto;
}
.app-modal__box{
  min-height: 100vh;
      background-color: #eef2f4;
}
.el-drawer__body{
  overflow: scroll;
}
</style>
