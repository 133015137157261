import { 
  staff_list_check_in, 
  staff_list_not_check_in,
   not_connect_telegram, 
   waiting_answer_telegram, 
   getTmsInfo, 
   getMostToiletStaffInfo,
   getHaveSalaryStaffs,
   getDoesntHaveSalayStaffs,
   getMoodStaffs,
   come_to_work,
   not_come_to_work,
   late_to_work,
   accepted_take_assent,
   sick_leave,
   on_vacation,
   come_sender,
   not_come_sender
  } from "@/api/staff/homeStaff";
import { now } from "lodash";
const timeElapsed = Date.now();
const date = new Date(timeElapsed);
const today = date.toLocaleDateString();
export const actions = {    
  come_to_work({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      come_to_work(params)
            .then(res => {
              let arr =[];
              let respon = res.data.result.data.staffs;
              respon.forEach((value, index) => {
                  value.name =  value.name
                  value.last_name =  value.last_name
                  value.middle_name =  value.middle_name
                  value.position_id = value.position?value.position.name:'';                       
                  value.company = value.company?value.company.name:'';                       
                  value.branch = value.branch?value.branch.name:'';  
                  item.today = today;                       
                  arr.push(value);
              });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);  
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
        });
    });
  },
  not_come_to_work({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      not_come_to_work(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                    let item = [];
                    item.id =  value.id;
                    item.name =  value.name;
                    item.last_name =  value.last_name;
                    item.middle_name =  value.middle_name;
                    item.position = value.position?value.position.name:'';                       
                    item.company = value.company?value.company.name:'';    
                    item.today = today;                       
                    arr.push(item);
                });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  late_to_work({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      late_to_work(params)
            .then(res => {
            
                let arr =[];
                let respon = res.data.result.data.staffs;
             
                respon.forEach((value, index) => {
                    let item = [];
                    item.id =  value.id;
                    item.name =  value.name;
                    item.last_name =  value.last_name;
                    item.middle_name =  value.middle_name;
                    item.position = value.position?value.position.name:'';                       
                    item.company = value.company?value.company.name:'';                       
                    item.punch_time = value.punch_time;                       
                    item.today = today;                       
                    arr.push(item);
                });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  accepted_take_assent({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      accepted_take_assent(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                    let item = [];
                    item.id =  value.id;
                    item.name =  value.name;
                    item.last_name =  value.last_name;
                    item.middle_name =  value.middle_name;
                    item.position = value.position?value.position.name:'';                       
                    item.company = value.company?value.company.name:'';   
                    item.today = today;                       
                    arr.push(item);
                });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  sick_leave({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      sick_leave(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                    let item = [];
                    item.id =  value.id;
                    item.name =  value.name;
                    item.last_name =  value.last_name;
                    item.middle_name =  value.middle_name;
                    item.position = value.position?value.position.name:'';                       
                    item.company = value.company?value.company.name:'';                       
                    item.today = today;                       
                    arr.push(item);
                });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  on_vacation({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      on_vacation(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                    let item = [];
                    item.id =  value.id;
                    item.name =  value.name;
                    item.last_name =  value.last_name;
                    item.middle_name =  value.middle_name;
                    item.position = value.position?value.position.name:'';                       
                    item.company = value.company?value.company.name:'';                       
                    item.today = today;                       
                    arr.push(item);
                });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  getTmsInfo({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getTmsInfo(params)
            .then(res => {
                commit("SET_TMS_INFO", res.data.result.data.tms);                     
                resolve(res.data.result.data.tms);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  getMostToiletStaffInfo({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getMostToiletStaffInfo(params)
        .then(res => {
          commit("SET_TOP_TOILET_ATTENDANTS", res.data.result.data.attandence_list);
          resolve(res.data.result.data.attandence_list)
        })
        .catch(err => {
          reject(err.response.data);
        });
    })
  },

  getMoodStaffs({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getMoodStaffs(params)
            .then(res => {
              let arr =[];
              let respon = res.data.result.data.staffs;
              respon.forEach((value, index) => {
                let item = [];
                item.id =  value.id;
                item.name =  value.name;
                item.last_name =  value.last_name;
                item.middle_name =  value.middle_name;
                item.position = value.position?value.position.name:'';                       
                item.company = value.company?value.company.name:'';                       
                arr.push(item);
            });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);  
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
},

  staff_list_check_in({ commit }, params={}) {
      return new Promise((resolve, reject) => {
        staff_list_check_in(params)
              .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                  let item = [];
                  item.id =  value.id;
                  item.name =  value.name;
                  item.last_name =  value.last_name;
                  item.middle_name =  value.middle_name;
                  item.position = value.position?value.position.name:'';                       
                  item.company = value.company?value.company.name:'';                       
                  item.today = today;                       
                  arr.push(item);                    

              });
                  commit("SET_EXCEL", arr);
                  commit("SET_STAFF_LIST", res.data.result.data.staffs);  
                  commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
  },
  staff_list_not_check_in({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      staff_list_not_check_in(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                  let item = [];
                  item.id =  value.id;
                  item.name =  value.name;
                  item.last_name =  value.last_name;
                  item.middle_name =  value.middle_name;
                  item.position = value.position?value.position.name:'';                       
                  item.company = value.company?value.company.name:'';                       
                  item.today = today;                       
                  arr.push(item);
              });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  not_connect_telegram({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      not_connect_telegram(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                  let item = [];
                  item.id =  value.id;
                  item.name =  value.name;
                  item.last_name =  value.last_name;
                  item.middle_name =  value.middle_name;
                  item.position = value.position?value.position.name:'';                       
                  item.company = value.company?value.company.name:'';                       
                  item.today = today;                       
                  arr.push(item);
              });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  waiting_answer_telegram({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      waiting_answer_telegram(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                  let item = [];
                  item.id =  value.id;
                  item.name =  value.name;
                  item.last_name =  value.last_name;
                  item.middle_name =  value.middle_name;
                  item.position = value.position?value.position.name:'';                       
                  item.company = value.company?value.company.name:'';                       
                  item.today = today;                       
                  arr.push(item);
              });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  come_sender({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      come_sender(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                    let item = [];
                    item.id =  value.id;
                    item.name =  value.name;
                    item.last_name =  value.last_name;
                    item.middle_name =  value.middle_name;
                    item.position = value.position?value.position.name:'';                       
                    item.company = value.company?value.company.name:'';                       
                    item.today = today;                       
                    arr.push(item);
                });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  not_come_sender({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      not_come_sender(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                  let item = [];
                  item.id =  value.id;
                  item.name =  value.name;
                  item.last_name =  value.last_name;
                  item.middle_name =  value.middle_name;
                  item.position = value.position?value.position.name:'';                       
                  item.company = value.company?value.company.name:'';                       
                  item.today = today;                       
                  arr.push(item);
                });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  getHaveSalaryStaffs({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getHaveSalaryStaffs(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                  let item = [];
                  item.id =  value.id;
                  item.name =  value.name;
                  item.last_name =  value.last_name;
                  item.middle_name =  value.middle_name;
                  item.position = value.position?value.position.name:'';                       
                  item.company = value.company?value.company.name:'';                       
                  item.today = today;                       
                  arr.push(item);
              });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  getDoesntHaveSalayStaffs({ commit }, params={}) {
    return new Promise((resolve, reject) => {
      getDoesntHaveSalayStaffs(params)
            .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                  let item = [];
                  item.id =  value.id;
                  item.name =  value.name;
                  item.last_name =  value.last_name;
                  item.middle_name =  value.middle_name;
                  item.position = value.position?value.position.name:'';                       
                  item.company = value.company?value.company.name:'';                       
                  item.today = today;                       
                  arr.push(item);
              });
                commit("SET_EXCEL", arr);
                commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                resolve(res.data.result.data.staffs);
            })
            .catch(err => {
                reject(err.response.data);
            });
    });
  },
  updateSort({ commit }, sort) {
    commit("SET_SORT", sort)
  },

  updateFilter({ commit }, filter) {
      commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
  },

  updateColumn({ commit }, obj) {
      commit('UPDATE_COLUMN', obj)
  },
  updatePagination({ commit }, pagination) {
      commit('UPDATE_PAGINATION', pagination)
  },
  refreshData({ commit }) {
      return new Promise((resolve, reject) => {
          commit("REFRESH");
          resolve()
      })
  },
  empty({ commit }) {
    return new Promise((resolve, reject) => {
        commit("EMPTY_MODEL");
        resolve()
    })
},
};
