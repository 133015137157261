export const rules = {
  staff_id: [
    {
      required: true,
      message: "Пожалуйста, введите название ",
      trigger: "change"
    }
  ],
  amount: [
    {
      required: true,
      message: "Пожалуйста, введите название ",
      trigger: "change"
    }
  ],
  comment: [
    {
      required: true,
      message: "Пожалуйста, введите название ",
      trigger: "change"
    }
  ]
};
